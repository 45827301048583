@import url("https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap");

.filter-by,
.sort-by {
  display: flex;
  flex-direction: column;
  font-size: 17px;
  line-height: 30px;
  font-weight: 300;
}



.filter-theme {
  border: 1px #00000010 solid;
}
.filter-theme label {
  font-family: "Helvetica";
}
.filter-theme input#filter-radio\[\] {
  display: none;
}

.filter-theme label::before {
  content: "\f111";
  font-family: "Font Awesome 5 Free";
  color: transparent;
  margin-right: 10px;
  font-size: 30px;
  height: 20px;
  display: inline-flex;
  align-items: center;
  border: 1px #AAC5E8 solid;
  margin-bottom: 10px;
}

.filter-theme label.active::before {
  color: green;
  background-color: green;
  font-size: 30px;
  height: 20px;
  margin-right: 10px;
  border: 1px green solid;
}
