.delete {
    position: absolute;
    right: 0;
    bottom: 0;
    cursor: pointer;
    text-decoration: underline;
}
.delete:hover {
    opacity: 0.6;
}
.description {
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    min-height: 140px;
}