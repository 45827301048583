.trainingCardList-container {
  min-height: 1000px;
  padding-top: 20px;
}

.training-list-row {
  display: flex;
  justify-content: flex-start;
  flex-direction: row;
  flex-flow: wrap;
  width: 100%;
  padding: 0px 5%;
  border-bottom: 1px #dcdcdc solid;
}

/* media queries for mobile */
@media only screen and (max-width: 768px) {
  .trainingCardList-container {
    min-height: auto;
  }
  .training-list-row {
    flex-direction: row;
    align-items: center;
    justify-content: center;
    padding: initial;
  }
}
