/* OBJECTIVES & PROGRAM   */

.elearning-objectives-program {
  width: 662px;
  height: 390px;
}

.objectives-program-header {
  width: 662px;
  margin-block-start: 0;
  padding-right: 38px;
  display: flex;
  flex-direction: row;
}

.objectives-program-header h4 {
  width: 331px;
  /* padding-right: 73px; */
  border-bottom: 2px solid var(--secondary-color);
  margin-block-start: 0;
  margin-block-end: 0;

  font-style: normal;
  font-weight: 500;
  font-size: 22px;
  color: var(--primary-color);
  padding-bottom: 7px;
}

funding .default-category {
  border-bottom: 2px solid var(--tertiary-color) !important;
}

.default-category:hover {
  border-bottom: 2px solid var(--tertiary-color);
}

.program-title-border {
  border-bottom: 2px solid var(--tertiary-color) !important;
}

.program-title {
  border-bottom: 2px solid var(--secondary-color);
}

.elearning-objectives-program .program-content {
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  line-height: 1.2em;
  margin-right: 15px;
  text-align: justify;
  height: 300px;
  /* set the maximum height for the section */
  overflow: auto;
  /* add scrollbar when the content overflows */
}

.elearning-objectives-program .objective-content {
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  line-height: 1.2em;
  margin: 20px 15px 0 0;
  text-align: justify;

  height: 300px;
  /* max-height: 300px; set the maximum height for the section */
  overflow: auto;
  /* add scrollbar when the content overflows */
}

.elearning-objectives-program .objective-content div,
.elearning-objectives-program .program-content div {
  padding-right: 10px;
}

@media (max-width: 768px) {
  .elearning-objectives-program {
    max-width: 100%;
    height: auto;
    width: auto;
  }

  .objectives-program-header {
    padding-right: unset;
    width: auto;
    justify-content: center;
  }

  .objectives-program-header h4 {
    width: 50%;
    margin: 0;
    font-size: 14px !important;
    text-align: center;
  }

  .elearning-objectives-program .objective-content,
  .elearning-objectives-program .program-content {
    font-size: 14px;
  }
}

/*
 *  SCROLLBAR
 */

#scrollbar::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  background-color: #f5f5f5;
}

#scrollbar::-webkit-scrollbar {
  width: 7px;
  background-color: #f5f5f5;
}

#scrollbar::-webkit-scrollbar-thumb {
  background-color: var(--secondary-color);
  background-image: -webkit-linear-gradient(
    45deg,
    rgba(255, 255, 255, 0.2) 25%,
    transparent 25%,
    transparent 50%,
    rgba(255, 255, 255, 0.2) 50%,
    rgba(255, 255, 255, 0.2) 75%,
    transparent 75%,
    transparent
  );
}
