@import url("https://use.typekit.net/lcn7qou.css");
@import url("https://use.typekit.net/czi7qfd.css");

@font-face {
	font-family: 'Helvetica';
  font-weight: normal;
	src: url('./fonts/Helvetica.ttf') format('truetype')
}
@font-face {
	font-family: 'Helvetica';
  font-weight: bold;
	src: url('./fonts/Helvetica-Bold.ttf') format('truetype')
}

@font-face {
	font-family: 'HelveticaNeue';
  font-weight: light;
	src: url('./fonts/HelveticaNeue-100.otf') format('truetype')
}
@font-face {
	font-family: 'HelveticaNeue';
  font-weight: normal;
	src: url('./fonts/Helvetica-Neue-Regular.otf') format('truetype')
}
@font-face {
	font-family: 'HelveticaNeue';
  font-weight: medium;
	src: url('./fonts/Helvetica-Neue-Medium.otf') format('truetype')
}


* {
  box-sizing: border-box;
}

.title-training {
  font-family: 'Helvetica', sans-serif;
  font-weight: bold !important;
}

#lang {
  position: absolute;
  top: 5px;
  left: 5px;
}

.col-numbers {
  border-right: 2px #16558A solid;
  border-radius: 0 !important;
  -webkit-border-radius: 0 !important;
  -moz-border-radius: 0 !important;
  -ms-border-radius: 0 !important;
  -o-border-radius: 0 !important;
}
.col-numbers:last-child {
  border: 0;
}