h1 {
    margin: 40px 0 20px 0;
    width: 100%;
    text-align: center;
}

.container {
    display: flex;
}

.left-column {
    flex: 1;
    padding: 10px;
}

.right-column {
    padding: 10px;
    margin-left: auto;
}