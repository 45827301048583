.pink-button {
  border: 2px solid var(--tertiary-color);
  width: 343px;
  margin: 16px 0;
  padding: 10px;
  border-radius: 35px;
  background-color: var(--tertiary-color);
  font-family: "filson-soft";
  font-size: 20px;
  font-weight: 500;
  text-align: center;
  color: var(--light-color);
  cursor: pointer;
}
.pink-button:hover {
  border: 2px solid var(--tertiary-color);
  background-color: var(--light-color);
  color: var(--tertiary-color);
  transition-duration: 0.5s;
}

/* media queries for smaller screens */
@media only screen and (max-width: 767px) {
  .pink-button {
    padding: 10 15px;
    /* width: 100%; */
    height: auto;
    font-size: 13px;
  }
}

.pink-button:hover {
  border: 2px solid var(--tertiary-color);
  background-color: var(--light-color);
  color: var(--tertiary-color);
  transition-duration: 0.5s;
}
