.elearning-description-container {
  text-align: left;
  padding-bottom: 10px;
  border-bottom: 2px solid var(--secondary-color);
  margin: 0 0px 42px 0px;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
}
.elearning-description-container div:first-child {
  align-self: center;
}
.elearning-description-container h2 {
  margin: 20px 0 35px 0px;
  font-size: 45px;
  font-weight: 500;
  color: var(--primary-color);
}
.info-container {
  display: flex;
  margin-top: -42px;
  margin-block-start: -4em;
}
.objectives-infos {
  display: flex;
  justify-content: center;
  /* gap: 300px; */
}

@media screen and (max-width: 768px) {
  .elearning-description-container {
    margin: 0 7px 20px 7px;
    height: auto;
    width: auto;
  }
  .info-container {
    text-align-last: center;
    width: fit-content;
  }

  .elearning-description-container h2 {
    font-size: 25px;
    text-align: center;
    margin: 20px 25px 25px 25px;
  }

  .elearning-description-container .info-container {
    flex-direction: column;
    margin-top: 20px !important;
    align-self: center;
    z-index: -1;
  }

  .objectives-infos,
  .program-infos {
    flex-basis: 100%;
    margin-bottom: 20px;
    display: flex;
    flex-direction: column;
    width: 100%;
  }
}
