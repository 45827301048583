#contact .nav li {
  margin-bottom: 20px;
}

#contact form {
  margin: 0;
}

#contact input,
#contact textarea {
  width: 100%;
  padding: 10px;
  border-radius: 3px;
  border: solid 1px #fff;
  background-color: rgba(255,255,255,.3);
  color: #fff;
  opacity: 0.5;
  text-transform: uppercase;
  -webkit-border-radius: 3px;
  -moz-border-radius: 3px;
  -ms-border-radius: 3px;
  -o-border-radius: 3px;
}

#contact input::placeholder,
#contact textarea::placeholder {
  color: #fff;
}

#contact input {
  height: 40px;
}
#contact textarea {
  height: 100px;
}

