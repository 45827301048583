#catalog #search {
  position: relative;
}
#catalog #search .ico-search {
  position: absolute;
  right: 0;
  top: 0;
  height: 43px;
  width: 43px;
  margin: 0;
  justify-content: center;
  align-items: center;
  border-radius: 0 7px 7px 0;
  -webkit-border-radius: 0 7px 7px 0;
  -moz-border-radius: 0 7px 7px 0;
  -ms-border-radius: 0 7px 7px 0;
  -o-border-radius: 0 7px 7px 0;
}
#catalog #search {
  font-size: 22px;
}
#catalog #search #product-search {
  border-radius: 7px;
  -webkit-border-radius: 7px;
  -moz-border-radius: 7px;
  -ms-border-radius: 7px;
  -o-border-radius: 7px;
  border: 1px rgba(0, 0, 0, 0.20) solid;
  padding: 12px 24px;
  width: 100%;
}
#catalog #search #product-search::placeholder {
  color: rgba(0, 0, 0, 0.20);
}

/* media queries for smaller screens */
@media only screen and (max-width: 768px) {
  .search-container {
    width: 100%;
  }
  .input-wrap {
    /* width: 230px; */
    width: auto;
    height: 46px;
    margin-left: 0;
    padding-left: 15px;
    gap: 35px;
  }
  .search-icon {
    height: 25px;
    width: 25px;
  }
  .fa-magnifying-glass {
    width: 13px;
  }

  #product-search {
    font-size: 12px;
    width: 145px;
    text-align-last: start;
    margin-left: 5px;
    padding-left: 0;
    /* width: auto; */
  }

  .input-wrap button[type="submit"] {
    height: 35px;
    font-size: 12px;
    margin-right: 0px;
  }
  .input-wrap button[type="submit"] svg {
    margin-left: 3px;
    width: 15px;
    height: 15px;
  }
  .titleSearch {
    font-size: 12px;
    color: var(--light-color);
    font-weight: 400;
    padding-bottom: 5px;
  }
}
