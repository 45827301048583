.header-bar {
  width: 100%;
  height: 105px;
  backdrop-filter: blur(25px);
  -webkit-backdrop-filter: blur(25px);
  background-color: var(--secondary-color-light);

  /* position: sticky;
  z-index: 99;
  top: 0; */
}

.header-bar > div {
  height: 105px;
}

/* LEFT-SECTION */
.left-section {
  display: flex;
  flex-direction: column;
}

.training-name {
  font-size: 22px;
}
.tracking-in-expand {
  -webkit-animation: tracking-in-expand 2s
    cubic-bezier(0.215, 0.61, 0.355, 1) both;
  animation: tracking-in-expand 2s
    cubic-bezier(0.215, 0.61, 0.355, 1) both;
}
.training-indication {
  font-style: normal;
  text-align: left;
  font-weight: 400;
  font-size: 18px;
  color: var(--primary-color);
}

/* RIGHT-SECTION */
.right-section {
  display: flex;
  flex-direction: row;
  align-items: center;
}

/* Animation tracking-in-expand */

@-webkit-keyframes tracking-in-expand {
  0% {
    letter-spacing: -0.5em;
    opacity: 0;
  }
  40% {
    opacity: 0.6;
  }
  100% {
    opacity: 1;
  }
}
@keyframes tracking-in-expand {
  0% {
    letter-spacing: -0.5em;
    opacity: 0;
  }
  40% {
    opacity: 0.6;
  }
  100% {
    opacity: 1;
  }
}

/* media queries for smaller screens */
@media only screen and (max-width: 768px) {
  .header-bar {
    padding: 10px 0;
    height: auto;
    display: flex;
    flex-direction: column;
    align-content: center;
    align-items: center;
  }

  .left-section {
    align-items: center;
    padding-top: 10px;
  }

  .chat-icon {
    margin-right: 5px;
    margin-top: -5px;
    height: 37px;
    width: 37px;
  }
}
