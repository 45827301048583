.add-to-cart button {
  border: none;
  width: 343px;
  height: 45px;
  margin: 16px 0;
  border-radius: 35px;
  background-color: var(--tertiary-color);
  font-family: "filson-soft";
  font-size: 20px;
  font-weight: 500;
  text-align: center;
  color: var(--light-color);
  cursor: pointer;
}
.add-to-cart button:hover {
  border: 2px solid var(--secondary-color);
  background-color: var(--light-color);
  color: var(--secondary-color);
  transition-duration: 0.5s;
}

/* media queries for smaller screens */
@media only screen and (max-width: 767px) {
  .add-to-cart button {
    padding: 0 10px;
    /* max-width: 55%; */
    width: 100%;
    height: 40px;
    font-size: 13px;
  }
}

.add-to-cart button:hover {
  border: 2px solid var(--secondary-color);
  background-color: var(--light-color);
  color: var(--secondary-color);
  transition-duration: 0.5s;
}
