#not-found {
    background-color: RGB(233, 237, 236);
    margin-top: 20px;
    height: 60vh;
    background-size: 50%;
    background-position: center;
    background-repeat: no-repeat;
}
#not-found > div {
    height: 60vh;
    padding: 63px 0;
}

@media screen and (max-width:1024px){
    #not-found {
        background-size: 40%;
        height: 100vh;
        margin-top: 0;
    }
    #not-found > div {
        height: 100vh;
    }
}

@media screen and (max-width:768px){
    #not-found {
        background-size: 80%;
        height: 70vh;
        margin-top: 0;
    }
    #not-found > div {
        height: 70vh;
    }
}