.certifications-infos-supcontainer {
  display: flex;
  justify-content: center;
}
.certification-infos-container {
  margin: 9px 170px 16px 170px;
  padding-bottom: 93px;
  /* border-bottom: 2px solid var(--secondary-color); */
  width: 1172px;
}
.rectangle {
  width: 1172px;
  height: fit-content;
  /* height: 121px; */

  padding: 22px 94px 16px 20px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}
.blue {
  background-color: var(--fourth-color);
}
.rectangle-name {
  width: 256px;
  margin-right: 72px;
  font-style: normal;
  font-weight: 500;
  font-size: 20px;
}
.rectangle-content {
  width: 736px;
  text-align: center;
  margin-right: 81px;
  font-style: normal;
  font-weight: 400;
  font-size: 20px;
  text-align: justify;
}

@media only screen and (max-width: 768px) {
  .certification-infos-container {
    margin: 15px 7px 15px 7px;
    padding-bottom: 50px;
    border-bottom: 2px solid var(--secondary-color);
    width: auto;
    /* width: calc(100% - 40px); */
  }

  .rectangle {
    /* width: calc(100% - 40px); */
    width: 100%;
    height: auto;
    padding: 20px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }

  .rectangle-name {
    width: 100%;
    margin: 0px;
    font-size: 18px;
    text-align: center;
    justify-content: center;
  }

  .rectangle-content {
    width: 100%;
    text-align: center;
    font-size: 14px;
    margin-right: 0;
    margin-top: -10px !important;
    justify-content: center;
  }
}
