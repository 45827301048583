@import url("https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap");

.configurator-container {
  height: 100vh;
  display: flex;
  flex-direction: row;
  /* margin-left: 170px; */
  z-index: 1;
  background-color: var(--light-color);
  position: relative;
}

.configurator-left-part {
  width: 700px;
  display: flex;
  flex-direction: column;
  height: 100vh;
}
.configurator-header {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  padding: 10px 24px;

  height: 92px;
  background: var(--primary-color);

  color: var(--light-color);
  font-weight: 400;
  font-size: 32px;
}
.configurator-header h1 {
  font-weight: 400;
  font-size: 32px;
  line-height: 38px;
}
.configurator-header p {
  font-weight: 300;
  font-size: 12px;
  line-height: 14px;
}
.configurator-image {
  height: 80vh;
}

/* CONFIGURATOR FOOTER LEFT   */
.configurator-footer {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 20px 24px;
  /* padding: 10px 24px; */
  gap: 20px;

  min-height: 136px;
  background: var(--primary-color);

  color: var(--light-color);
}
.configurator-price h6 {
  font-weight: 300;
  font-size: 22px;
  line-height: 26px;
}
.configurator-price span {
  font-weight: 500;
  font-size: 32px;
  line-height: 38px;
}
.add-to-cart {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  gap: 15px;

  width: 267px;
  height: 66px;

  background: var(--tertiary-color);
  border-radius: 12px;
  border: none;

  transition: 0.5s ease;

  color: var(--light-color);
  font-weight: 500;
  font-size: 18px;
  line-height: 26px;
}
.add-to-cart:hover {
  color: var(--tertiary-color);
  background: var(--light-color);
  cursor: pointer;
}
.selected-value {
  font-weight: 100;
  font-size: 18px;
  line-height: 26px;
  color: var(--light-color);
  opacity: 1;
  transition: opacity 0.5s ease;
}

.configurator-right-part {
  display: flex;
  flex-direction: column;

  align-items: flex-start;
  padding: 12px 60px;
  gap: 30px;
  /* gap: 36px; */
  width: 740px;
}
.configurator-right-part h2 {
  font-weight: 500;
  font-size: 43px;
  line-height: 52px;

  color: var(--primary-color);
}
.configurator-right-part h2 span {
  color: var(--secondary-color);
}
.configurator-right-part h3 {
  font-family: "Roboto";
  font-style: normal;
  font-weight: 400;
  font-size: 30px;
  line-height: 35px;
  color: var(--dark-color);
}

/* VECTORS LIST  */
.configurator-container .vecteurs-list {
  display: flex;
  flex-direction: row;
  align-items: flex-end;
  padding: 0px;
  gap: 6px;

  width: 679px;
  height: 144px;
}
.vecteur-card {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 10px;

  width: 119px;
  height: 104px;

  background: #fbfbfb;
  box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.25);
  border-radius: 12px;
  cursor: pointer;
}
.vecteur-card.active {
  background-color: var(--fifth-color);
}
.vecteur-card.active h2,
.vecteur-card.active p {
  color: var(--light-color);
}
.vecteur-card h2,
.vecteur-card p {
  font-family: "Roboto";
  font-weight: 400;
  font-size: 15px;
  line-height: 18px;
  color: var(--dark-color);
}
.vecteur-card p {
  font-weight: 500;
}
.vecteur-card-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 6px;
  gap: 10px;

  width: 131px;
}
.vecteur-card-container:nth-last-child(-n + 2) {
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  align-items: center;
  padding: 6px;
  gap: 12px;

  width: 131px;
  height: 141px;

  background: #f1f7ff;
  border-radius: 12px;
}
.vecteur-card-container span {
  font-family: "Roboto";
  font-weight: 400;
  font-size: 11px;
  line-height: 13px;
  color: var(--dark-color);
}
.star {
  background-image: url("https://imagedelivery.net/KxaxjiZHclDBBV_yzb3fDg/983e011d-d52a-4c14-0212-c826d75cd800/public");
  background-size: contain;
  background-repeat: no-repeat;
  background-position-x: 7px;
  background-position-y: -1px;
  width: 100%;
  text-align: center;
}

/* FOOTER OPTION  */
.configurator-options {
  flex-direction: column;
  align-items: flex-start;
  padding: 0px;
  gap: 12px;
}
.configurator-option {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 0px;
  gap: 36px;
  /* SIZE REDUCER  */
  margin-bottom: -15px;

  width: 673px;
}
.configurator-option div:first-child {
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 0px;
  gap: 22px;
  height: 101px;
}
.configurator-option h3,
.configurator-option span {
  font-weight: 400;
  font-size: 20px;
  line-height: 23px;
  color: var(--dark-color);
}
.configurator-option p {
  font-style: normal;
  font-weight: 300;
  font-size: 20px;
  line-height: 23px;
  color: var(--dark-color);
}
.configurator-option img {
  width: 60px;
}
