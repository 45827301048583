/** SEARCH HERO **/
#search-hero {
  width: 80%;
}
#search-hero > div > div:first-child {
  width: 15%;
}
#search-hero > div > div:first-child svg {
  font-size: 25px;
}
#search-hero input {
  margin: 0;
  border: 0;
  padding-left: 0;
  padding-right: 0;
}
#search-hero input::placeholder {
  color: #c3c3c3;
}


#search-hero button svg {
  position: absolute;
  top: 15px;
  right: 5px;
}




.row {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  width: 80%;
}

.training {
  box-shadow: 5px 4px 4px rgb(0 0 0 / 15%);
  overflow: hidden;
  transition: all 0.5s ease-in-out;
  -webkit-transition: all 0.5s ease-in-out;
  -moz-transition: all 0.5s ease-in-out;
  -ms-transition: all 0.5s ease-in-out;
  -o-transition: all 0.5s ease-in-out;
}
.training:hover {
  box-shadow: none;
}



.white-bloc > div {
  min-height: 150px;
  padding: 10px;
  position: relative;
}
.white-bloc > div > div {
  align-items: flex-end;
  position: absolute;
  bottom: 0;
}
.white-bloc > div > div p {
  padding-bottom: 10px;
}



.color-arrow-facilit > div,
.color-arrow-arel > div,
.color-arrow-skills > div {
  position: relative;
  border-radius: 0;
  -webkit-border-radius: 0;
  -moz-border-radius: 0;
  -ms-border-radius: 0;
  -o-border-radius: 0;
  margin: 0;
}
.color-arrow-facilit .arrow-right,
.color-arrow-arel .arrow-right,
.color-arrow-skills .arrow-right {
  clip-path: polygon(0 0, 0 100%, 100% 50%);
  width: 20px;
  height: 42px;
  position: absolute;
  right: -20px;
  top: 0;
}
.color-arrow-facilit > div:first-child,
.color-arrow-arel > div:first-child,
.color-arrow-skills > div:first-child {
  margin-left: 0;
}
.color-arrow-facilit > div:first-child,
.color-arrow-facilit > div:first-child .arrow-right {
  background-color: #fff;
  z-index: 5;
}
.color-arrow-arel > div:first-child,
.color-arrow-arel > div:first-child .arrow-right {
  background-color: #FFF3EF;
  z-index: 5;
}
.color-arrow-skills > div:first-child,
.color-arrow-skills > div:first-child .arrow-right {
  background-color: #fff;
  z-index: 5;
}
.color-arrow-facilit > div:nth-child(2),
.color-arrow-facilit > div:nth-child(2) .arrow-right {
  background-color: #E9F1F9;
  z-index: 4;
}
.color-arrow-arel > div:nth-child(2),
.color-arrow-arel > div:nth-child(2) .arrow-right {
  background-color: #FFECE5;
  z-index: 4;
}
.color-arrow-skills > div:nth-child(2),
.color-arrow-skills > div:nth-child(2) .arrow-right {
  background-color: #DAFEF3;
  z-index: 4;
}
.color-arrow-facilit > div:nth-child(3),
.color-arrow-facilit > div:nth-child(3) .arrow-right {
  background-color: #DEEAF6;
  z-index: 3;
}
.color-arrow-arel > div:nth-child(3),
.color-arrow-arel > div:nth-child(3) .arrow-right {
  background-color: #FFDED2;
  z-index: 3;
}
.color-arrow-skills > div:nth-child(3),
.color-arrow-skills > div:nth-child(3) .arrow-right {
  background-color: #B7F2E0;
  z-index: 3;
}
.color-arrow-facilit > div:nth-child(4),
.color-arrow-facilit > div:nth-child(4) .arrow-right {
  background-color: #D8E8F8;
  z-index: 2;
}
.color-arrow-arel > div:nth-child(4),
.color-arrow-arel > div:nth-child(4) .arrow-right {
  background-color: #FFCEBC;
  z-index: 2;
}
.color-arrow-skills > div:nth-child(4),
.color-arrow-skills > div:nth-child(4) .arrow-right {
  background-color: #83E2C5;
  z-index: 2;
}
.color-arrow-facilit > div:nth-child(5),
.color-arrow-facilit > div:nth-child(5) .arrow-right {
  background-color: #BAD4ED;
  margin-right: 0;
  z-index: 1;
}
.color-arrow-arel > div:nth-child(5),
.color-arrow-arel > div:nth-child(5) .arrow-right {
  background-color: #FFA989;
  margin-right: 0;
  z-index: 1;
}
.color-arrow-skills > div:nth-child(5),
.color-arrow-skills > div:nth-child(5) .arrow-right {
  background-color: #47DCAE;
  margin-right: 0;
  z-index: 1;
}

.color-bloc-facilit > div,
.color-bloc-arel > div,
.color-bloc-skills > div {
  min-height: 200px;
}
.color-bloc-facilit > div:first-child {
  margin-left: 0;
  background-color: #fff;
}
.color-bloc-arel > div:first-child {
  margin-left: 0;
  background-color: #FFF3EF;
}
.color-bloc-skills > div:first-child {
  margin-left: 0;
  background-color: #fff;
}
.color-bloc-facilit > div:nth-child(2) {
  background-color: #E9F1F9;
}
.color-bloc-arel > div:nth-child(2) {
  background-color: #FFECE5;
}
.color-bloc-skills > div:nth-child(2) {
  background-color: #DAFEF3;
}
.color-bloc-facilit > div:nth-child(3) {
  background-color: #DEEAF6;
}
.color-bloc-arel > div:nth-child(3) {
  background-color: #FFDED2;
}
.color-bloc-skills > div:nth-child(3) {
  background-color: #B7F2E0;
}
.color-bloc-facilit > div:nth-child(4) {
  background-color: #D8E8F8;
}
.color-bloc-arel > div:nth-child(4) {
  background-color: #FFCEBC;
}
.color-bloc-skills > div:nth-child(4) {
  background-color: #83E2C5;
}
.color-bloc-facilit > div:nth-child(5) {
  background-color: #BAD4ED;
  margin-right: 0;
}
.color-bloc-arel > div:nth-child(5) {
  background-color: #FFA989;
  margin-right: 0;
}
.color-bloc-skills > div:nth-child(5) {
  background-color: #47DCAE;
  margin-right: 0;
}


#testimonial .desc {
  line-height: 23px;
}
#testimonial .name {
  font-family: 'Open Sans', sans-serif !important;
  font-size: 15px;
}
#testimonial > div > div {
  width: 31%;
}

#testimonial .height-bloc {
  height: 300px;
}



.get-facilit {
  background: linear-gradient(180deg, rgba(136,206,255,1) 0%, rgba(255,255,255,1) 100%);
}
.get-arel {
  background: linear-gradient(180deg, rgba(254,240,235,1) 0%, rgba(255,255,255,1) 100%);
}
.get-skills {
  background: linear-gradient(180deg, rgba(231,246,239,1) 0%, rgba(255,255,255,1) 100%);
}


#home #form > div {
  position: relative;
}
#home #form > div > div {
  width: 90%;
  margin: 0;
}
#home #form > div > div form {
  max-width: 80%;
}
.dropdownSelect:last-child {
  margin-left: 40px;
}






@media (max-width: 768px) {
  #home #hero h2 {
    color: var(--light-color);
  }
  #home #hero > div {
    background-image: linear-gradient(
      45deg,
      black,
      transparent
    );
  }
  .searchBar-hero > div {
    border-radius: 35px;
  }
  .searchBar-hero,
  .searchBar-hero button[type="submit"],
  .searchBar-hero input[type="text"] {
    width: 100%;
  }
  .searchBar-hero button[type="submit"] {
    border-radius: 50%;
    margin-right: 0;
    width: 26%;
    padding: 0;
    margin-right: 3px;
  }
  .searchBar-hero input#product-search {
    width: 100%;
  }
}

/* Trainings */
#home #trainings > div > ul > li {
  text-align: center;
}

#home #trainings > div > ul {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
}

#home #trainings > div > ul > li {
  padding: 7px 0;
  background-color: #f2f8ff;
  margin-bottom: 5px;
  width: 32%;
  margin-right: 1%;
}
#trainings h2 {
  border-bottom: 5px solid var(--tertiary-color);
  width: fit-content;
}


#home #trainings li > p {
  position: relative;
}

#home #trainings > div > ul > li span {
  display: inline-block;
  text-align: center;
  width: 30px;
  margin-right: 10px;
  padding-top: 2px;
}

#home .training h4 {
  font-family: "Montserrat";
}

#home .training p {
  font-size: 15px;
  text-transform: lowercase;
  min-height: 60px;
  display: flex;
  flex-direction: column;
  justify-content: center;
}
#home .training p::first-letter {
  text-transform: uppercase !important;
}

#home #trainings svg {
  font-size: 30px;
  background-color: var(--light-color);
  padding: 5px;
  box-shadow: 0px 2px 5px rgba(33, 38, 79, 0.25);
  border-radius: 6px;
}

.trainingList {
  display: flex;
  align-items: center !important;
  text-align: center;
  justify-content: center !important;
  margin: 0 20px;
}

@media (max-width: 768px) {
  .trainingList {
    justify-content: flex-start !important;
    padding: 5px 25px !important;
  }
  #home #trainings > div > ul > li p {
    padding: 0 !important;
  }
  #home #trainings li {
    width: 98% !important;
    margin: 1%;
    border-radius: 30px;
    -webkit-border-radius: 30px;
    -moz-border-radius: 30px;
    -ms-border-radius: 30px;
    -o-border-radius: 30px;
  }
  #home #trainings li > div {
    align-items: flex-start;
  }
  #home #trainings li span {
    margin-right: 0;
  }
}

/* Section entreprise hero */

.enterpriseHero-container {
  height: 70vh;
  display: flex;
  background: radial-gradient(
    62.68% 83.75% at 22.98% 47.2%,
    rgba(54, 36, 0, 0.3) 36.98%,
    rgba(54, 36, 0, 0) 98.44%
  );
}

.enterpriseHero-container > div,
.enterpriseHero-container > div > div,
.enterpriseHero-container > div > div > div {
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
}



/* personnaliser ma formation */
#home #personalize {
  min-height: 525px;
}

#home form.form-personalize {
  margin-top: 40px;
}

::placeholder {
  color: var(--secondary-color-light);
}

.success-message {
  color: var(--light-color);
  padding-top: 70px;
  font-weight: 700;
  font-size: 20px;
}

/* Testimonial */

#home .testimonial-author {
  font-size: 20px;
  color: var(--dark-color);
  margin: 0;
}

#home .rating img {
  width: 20px;
}

#home #testimonial span {
  color: var(--tertiary-color);
}

@media (max-width: 768px) {
  #home #testimonial > div > div:last-child {
    margin: -15px 0;
  }
}
.testimonials-slogan {
  font-weight: 500;
}
.team-signature {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  gap: 10px;
  margin: 15px 0 75px 0;
}
.team-signature img {
  width: 215px;
}

.testimonials-model {
  width: 40%;

  /* position: relative; */
  z-index: 0;
}
.testimonials-model img {
  opacity: 0.85;
  /* position: absolute; */
  background: linear-gradient(
    to right,
    rgb(255, 255, 255) 0%,
    #bcd1ec27 50%
  );
}

@media (max-width: 768px) {
  .team-signature {
    justify-content: center;
    align-items: center;
    gap: 10px;
  }
  .testimonials-model {
    width: 50%;
  }
  .testimonials-model img {
    background: transparent;
  }
}
