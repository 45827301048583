.faq-container {
  display: flex;
  max-width: 1140px;
  margin: auto;
  flex-direction: column;
  align-items: center;
  padding: 110px 0;
  gap: 10px;
  background: var(--light-color);
}
.faq-container h3 {
  font-weight: 500;
  font-size: 45px;
  line-height: 120%;
  color: var(--primary-color);
  align-self: flex-start;
  margin-bottom: 40px;
}

.faq-container div {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: center;
  padding: 0px;
  gap: 60px;

  width: 100%;
}
.faq-container img {
  width: 506px;
  border-radius: 5px;
  transition: all 0.4s ease-in-out;
}
.faq-container img:hover {
  box-shadow: 0px 10px 20px rgb(0 0 0 / 50%);
}
.faq-section {
  width: 614px !important;
  display: flex !important;
  flex-direction: column !important;
  justify-content: flex-start !important;
  gap: 0px !important;
}
.faq-item {
  display: flex;
  flex-direction: column;
  border-radius: 5px;
  margin-bottom: 10px;
  width: 100%;
  padding: 24px;

  transition: 0.7s all;
  overflow: hidden;

  height: fit-content;
}
.faq-section .open {
  display: flex;
  flex-direction: column;
  gap: 5px;
  height: fit-content;
}

.faq-item .faq-question {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 10px;

  border-bottom: 3px solid var(--fourth-color);
  cursor: pointer;
  height: 78px;

  font-weight: 700;
  font-size: 18px;
  color: var(--dark-color);
}

.faq-item .faq-icon {
  font-size: 26px;
  font-weight: 300;
  margin-left: 10px;
  color: var(--primary-color);
}

.faq-item .faq-answer {
  padding: 10px;
  background-color: var(--light-color);
  min-width: fit-content;
  font-weight: 400;
  font-size: 16px;
  line-height: 140%;
  color: var(--dark-color);
  text-align: justify;
}

.faq-item .faq-answer-block {
  padding: 10px;
  background-color: var(--light-color);

  font-weight: 400;
  font-size: 16px;
  line-height: 140%;
  color: var(--dark-color);
}
.faq-item .faq-answer-hidden {
  padding: 10px;
  background-color: var(--light-color);

  font-weight: 400;
  font-size: 16px;
  line-height: 140%;
  color: var(--dark-color);
}

@media only screen and (max-width: 768px) {
  .faq-container {
    padding: 80px 30px;
    gap: 20px;
    height: auto;
  }

  .faq-container h3 {
    font-size: 30px;
    margin-bottom: 20px;
  }

  .faq-container div {
    flex-direction: column;
    align-items: center;
    gap: 30px;
  }

  .faq-container img {
    width: 100%;
  }

  .faq-section {
    width: 100% !important;
  }

  .faq-item {
    flex-direction: row;
    padding: 16px;
    height: auto;
  }
  .faq-item .faq-icon {
    margin-left: 0;
  }

  .faq-item .faq-question {
    height: auto;
    font-size: 16px;
    flex-direction: row;
    padding: 10px 0;
  }

  .faq-item .faq-answer,
  .faq-item .faq-answer-block,
  .faq-item .faq-answer-hidden {
    font-size: 14px;
  }
}
