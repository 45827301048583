#entreprises .section2 .desc {
    padding: 26px;
    min-height: 150px;
}

#entreprises .section3 ul {
    margin-bottom: 50px;
}
#entreprises .section3 .index {
    font-size: 120px;
    text-align: center;
    width: 100px;
    margin-right: 20px;
}
@media (max-width: 768px) {
    #entreprises .section3 .index {
        width: 100% !important;
        margin-right: 0 !important;
        text-align: center;
    }
    #entreprises .section3 .index p {
        text-align: center;
    }
}