.recruiter-form {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin: auto;
  width: 830px;
}

#recruiter .label-file {
  display: flex;
  justify-content: center;
  width: fit-content;
  cursor: pointer;
  background: rgba(170, 197, 232, 0.52);
  border-radius: 30px;
  padding: 10px 20px;
  -webkit-border-radius: 30px;
  -moz-border-radius: 30px;
  -ms-border-radius: 30px;
  -o-border-radius: 30px;
  color: rgba(0, 0, 0, 0.51);
}
#recruiter .label-file:hover {
  background-color: var(--primary-color);
  color: #fff;
  transition: all 0.5s ease-in-out;
}

#recruiter .input-file {
  display: none;
}
.enterprise-checkboxes {
  margin: 10px;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  gap: 75px;
  align-items: center;
  padding: 50px 0;
}
.enterprise-checkboxes div:first-child {
  width: 110px;
}
.checkboxes-list {
  width: 620px;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;

  flex-wrap: wrap;
  row-gap: 30px;
  column-gap: 20px;
}
.checkboxes-list label {
  min-width: 140px;
  gap: 10px;
  font-weight: 400;
  font-size: 16px;
  line-height: 16px;
  display: flex;
  justify-content: start;
  flex-direction: row-reverse;
  align-items: center;

  color: #21264f;
}
.recruiter-form input[type="submit"] {
  cursor: pointer;
  width: fit-content;
  border: 4px solid var(--primary-color);
  border-radius: 50px;
  padding: 5px 50px;
  transform: scale(1);
  transition: box-shadow 0.4s, transform 0.4s;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 10;
  background: var(--light-color);

  color: var(--primary-color);
  border-radius: 50px;
  font-weight: 400;
  text-align: center;
  font-size: 17px;
}
.recruiter-form input[type="submit"]:hover {
  transform: scale(1.05);
  background: var(--primary-color);
  border: 4px solid var(--primary-color);
  color: var(--light-color);
}

@media (max-width: 768px) {
  .label-file {
    margin: auto;
  }
  .recruiter-form {
    width: 100%;
    padding: 0 20px;
  }

  .enterprise-checkboxes {
    flex-direction: column;
    gap: 20px;
    padding: 30px 0;
  }

  .checkboxes-list {
    row-gap: 20px;
    width: 100%;
  }

  .checkboxes-list label {
    /* min-width: 100%; */
    justify-content: start;
  }
}
