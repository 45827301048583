:root {
  --primary-color: #21264f;
  --secondary-color: #afc4e5;
  --tertiary-color: #ff6565;
  --fourth-color: #e5edf8;
  --fifth-color: #3db9f8;
  --light-color: #fff;
  --dark-color: #000;
  --primary-color-light: #21264f80;
  --secondary-color-light: #aac5e880;
}

body {
  margin: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

body::-webkit-scrollbar {
  display: none;
}

a {
  text-decoration: none;
  color: inherit;
}

ul,
li,
figure {
  list-style: none;
  padding: 0;
  margin: 0;
}

img {
  width: 100%;
  max-width: 100%;
}

.elearning-configurator {
  display: flex;
  justify-content: center;
}

@media screen and (max-width:768px) {
  .training-name.tracking-in-expand > h3 {
    text-align: center;
    padding: 5px;
    font-size: 1.1em;
  }

  .configurator-left-part {
    width: 100% !important;
  }
  .configurator-footer {
    flex-direction: column !important;
    text-align: center;
  }
}
h1,
h2,
h3,
h4,
h5,
h6,
p {
  margin-block: 0;
}
button {
  cursor: pointer;
}
.bloc-slider.initial {
  opacity: 1;
}

.bloc-slider.transition {
  opacity: 0;
  transition: opacity 0.5s ease-in-out;
}

@media (max-width: 768px) {
  .blue-trophy,
  .target-strategy {
    width: 30%;
  }
}
pre::-webkit-scrollbar {
  display: none;
}
pre {
  overflow-x: hidden;
  white-space: pre-wrap;
}
.fade-in-left {
  opacity: 0;
  transform: translateX(-100%);
  transition: opacity 0.5s, transform 2.5s;
}

.fade-in-left.fade-in-left-active {
  opacity: 1;
  transform: translateX(0);
}
