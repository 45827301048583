.squared {
  width: 90px;
  display: inline-block;
  vertical-align: middle;
  margin: 0px 10px;
}

footer {
  color: var(--light-color);
  background-color: var(--primary-color);
  position: relative;
}

footer h4 {
  font-family: 'Open Sans', sans-serif;
  margin-bottom: 20px !important;
}

footer form {
  max-width: 70%;
}

footer li {
  margin: 10px 0;
  font-family: 'Open Sans', sans-serif;
  font-weight: 300;
}

footer svg {
  margin-right: 10px;
  font-size: 16px;
}

footer section:first-child > div {
  align-items: center;
}

footer .social {
  display: flex;
}
footer .social svg {
  font-size: 20px;
}

.qualiopi {
  width: 40%;
  margin-right: 20px;
}

.logoFooter {
  max-width: 90px;
}

.relative {
  position: relative;
}


#where {
  position: absolute;
  width: auto !important;
  right: 0;
  bottom: -40px;
  max-width: 360px;
}
#where li {
  margin-bottom: 35px;
  list-style-position: inside;
}

footer #where svg {
  margin-right: 20px;
  font-size: 20px;
}

.where-ico-facilit {
  color: #32B2F1;
}
.where-ico-arel {
  color: #EB612C;
}
.where-ico-skills {
  color: #F1CA09;
}