.dropdownSelect {
  background-color: var(--light-color);
  position: relative;
  display: flex;
  align-items: center;
  cursor: pointer;
  width: 100%;
  max-height: 42px;
  border-radius: 0;
  border: 1.5px solid #C9C9C9;
  padding: 11px 30px;
  text-transform: uppercase;
  font-size: 14px;
  -webkit-border-radius: 0;
  -moz-border-radius: 0;
  -ms-border-radius: 0;
  -o-border-radius: 0;
}

.dropdownSelect .dropdown-header {
  cursor: pointer;
  display: flex;
  justify-content: start;
  align-items: center;
  color: var(--secondary-color);
  font-size: 14px;
  max-height: 42px;

  text-overflow: ellipsis;
  white-space: nowrap;
  max-width: 100%;
  overflow: hidden;
}
.dropdownSelect .default-category {
  color: #d4e1f4;

  border: none !important;
}

.dropdownSelect .dropdown-body {
  padding: 5px;
  display: none;
  z-index: 9999;

  transition: all 0.2s ease-in-out;
}

.dropdownSelect .dropdown-body.open {
  display: block;
  position: absolute;
  background-color: var(--light-color);
  width: inherit;
  box-shadow: 0 5px 20px #00000022;
  border-radius: 10px;
  width: 100%;
  top: 45px;
  left: 0;
  margin-top: 0;

  transition: all 0.15s cubic-bezier(0.25, 0, 0.25, 1.75),
    opacity 0.1s linear;
}

.dropdownSelect .dropdown-item {
  padding: 10px;
  text-align: left;
  color: var(--primary-color);
  white-space: pre-wrap;
}

.dropdownSelect .dropdown-item:hover {
  cursor: pointer;
  background-color: var(--secondary-color);
  color: var(--light-color);
  border-radius: 10px;
}

.dropdownSelect .dropdown-item-dot {
  opacity: 0;
  color: var(--secondary-color);
  transition: all 0.2s ease-in-out;
}

.dropdownSelect .dropdown-item-dot.selected {
  opacity: 1;
}

.dropdownSelect .icon {
  font-size: 13px;
  color: var(--secondary-color);
  transform: rotate(0deg);
  transition: all 0.2s ease-in-out;
  padding: 0 15px 0 0;
}

.dropdownSelect .icon.open {
  transform: rotate(90deg);
  padding: 0 15px 0 15px;
  margin-left: -15px;
}

input:focus,
textarea:focus,
.dropdownSelect:focus,
.dropdownSelect:active,
.dropdownSelect:hover {
  -webkit-transition: 0.5s;
  transition: 0.5s;
  outline: none !important;
}

/* media queries for smaller screens */
@media only screen and (max-width: 768px) {
  .dropdownSelect {
    width: 100%;
    height: 45px;
    margin-right: 0;
    margin-left: 0;
    margin-bottom: 5px;
  }
  #home .dropdownSelect {
    width: 98%;
  }
  .dropdownSelect {
    padding: 0px !important;
  }

  .dropdownSelect .dropdown-body.open {
    margin-top: 5px;
    width: 100%;
  }
  .dropdownSelect .dropdown-header {
    font-size: 12px;
  }
  .dropdownSelect .dropdown-item {
    font-size: 12px;
  }
}
