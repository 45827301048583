@import url('https://fonts.googleapis.com/css2?family=Open+Sans:wght@600;700&display=swap');

#header {
  position: sticky;
  top: 0;
  z-index: 10;
}

/* //// */
#header nav li a {
  position: relative;
  padding-bottom: 4px;
}

#header nav li a::after {
  content: "";
  position: absolute;
  left: 0;
  bottom: 0;
  width: 0%;
  height: 2px;
  background-color: var(--secondary-color);
  transition: width 0.3s ease-in-out;
  cursor: pointer;
}

#header nav li:last-child a::after {
  content: "";
  position: absolute;
  left: 0;
  bottom: 0;
  width: 0%;
  height: 0px;
  cursor: pointer;
}

#header nav li a:hover::after {
  width: 100%;
}

/* ///// */

.selected {
  color: var(--secondary-color);
}

#header nav ul {
  display: flex;
  justify-content: flex-end;
  align-items: center;
}

#header nav li {
  list-style: none;
  padding: 5px;
  margin: 5px;
}

.take_appointment {
  font-family: 'Open Sans', sans-serif !important;
  font-weight: 700;
}
.take_appointment svg {
  margin-left: 10px;
}

@media (max-width: 990px) {
  #header {
    top: 38px;
  }
  #header > div {
    flex-direction: row;
    justify-content: space-around;
  }

  #header .logo {
    padding: 10px;
    max-width: 160px;
  }

  .menu {
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: -60%;
    width: 60%;
    z-index: 9999;
    background: var(--light-color);
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
    transition: left 0.3s ease;
  }

  .menu.open {
    left: 0;
  }

  .menu__header {
    display: flex;
    justify-content: flex-end;
    padding: 10px;
  }
  .menu__header button svg {
    font-size: 30px;
  }

  .menu__toggle {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    width: 30px;
    height: 30px;
    cursor: pointer;
    border: none;
    background-color: transparent;
    padding: 0;
  }

  .menu__toggle__line {
    width: 100%;
    height: 2px;
    background-color: #222;
    transition: transform 0.3s ease;
  }

  .menu__toggle__line:nth-child(2) {
    margin-top: 4px;
    margin-bottom: 4px;
  }

  .menu__item {
    list-style: none;
    margin: 0;
    padding: 0;
  }

  .menu__item a {
    display: block;
    padding: 10px 20px;
    font-size: 16px;
    color: #333;
    text-decoration: none;
    transition: background-color 0.3s ease;
  }

  .menu__item a:hover,
  .menu__item a:focus {
    background-color: #f7f7f7;
  }
  #header nav .menu__items {
    flex-direction: column;
  }
}
